import React from "react";
import { useState, useEffect } from "react";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid, Pagination } from "@mui/material";
import { defaultRequest, exportarCsv } from "../../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import "../Acessos/acessos.css";
import Row from "./rowResumoDiario";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import { Skeleton } from "@mui/material";

export default function ResumoDiario() {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const [page, setPage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [pageCount, setPageCount] = useState("");

  useEffect(async () => {
    setLoadingTable(true);

    try {

      let foo2 = await defaultRequest("/logDiarioGestaoOfertados", { pagina: page });
      setDadosOriginais(foo2.dados);
      setDadosTable(foo2.dados);
      setPage(foo2.pagina);
      setPageCount(Math.floor(foo2.total / 30));

      let array = [];
      foo2.dados.map((dado) => array.push(dado.COLECAO));
      array = [...new Set(array)];
      setColecoes(array);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }, [page]);

  const exportar = async () => {
    exportarCsv(dadosTable, "LOG_RESUMO_DIARIO_FAROL");
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={"all the time"}
            setBusca={false}
            setLoading={false}
            tipoTela={"LOG"}
            title={"LOG RESUMO DIÁRIO"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total box-card-total">
            {/* <div className="divider-pacote" /> */}

            <div className="box-geral resumo-diario">
              <ScrollSelect
                colecoes={colecoes}
                params={params}
                setParams={setParams}
                dadosOriginais={dadosOriginais}
                setDadosTable={setDadosTable}
                tela={"log resumo diário"}
              />
              {/* <h2 className="titulo-acesso">LOG RESUMO DIÁRIO</h2> */}
              <button className="btn-exportar-log" onClick={exportar}>
                Exportar
              </button>
            </div>

            {dadosTable.length > 0 ? (
              <>
                {loadingTable ? (
                  <div style={{ textAlign: "center" }}>
                    <Skeleton className="skeleton-format" variant="rectangular" height={200} />
                  </div>
                ) : (
                  <>
                    <Grid container className="">
                      <table className="table-border" cellspacing="0">
                        <thead>
                          <tr className="header-tabela">
                            <th className="th">Coleção</th>
                            <th className="th">Data</th>
                            <th className="th">Receita</th>
                            <th className="th">Receita Ofertados</th>
                            <th className="th">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dadosTable.map((iten) => {
                            return <Row row={iten} />;
                          })}
                        </tbody>
                      </table>
                    </Grid>
                    <div className="wrapper-pagination">
                      <Pagination
                        shape="rounded"
                        color="primary"
                        count={pageCount + 1}
                        page={page + 1}
                        onChange={(e, value) => setPage(value - 1)}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <Skeleton className="skeleton-format" variant="rectangular" height={200} />
            )}
          </div>
        }
      />
    </>
  );
}
